var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-breadcrumbs',{staticClass:"pa-0 mb-8",attrs:{"items":_vm.breadcrumbsItems},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"d-flex align-center justify-center"},[_c('v-btn',{staticClass:"mr-3 secondary--text pa-0 pr-3",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({ name: 'Meetings' })}}},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-chevron-left")]),_vm._v(" Meetings ")],1),_c('h2',{staticClass:"darkGrey--text d-flex flex-column custom-section-title"},[_c('span',[_vm._v("Finalized "),_c('span',{staticClass:"font-weight-black"},[_vm._v("Meeting Name")])]),_c('p',{staticClass:"text-body-1"},[_vm._v("for Board Name")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"darkGrey","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" Explanation for Board Members ")])])],1)])],1),_c('v-row',{staticClass:"my-6"},[_c('v-col',[_c('v-divider',{staticClass:"custom-divider"})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"hide-default-header":"","hide-default-footer":"","items":_vm.finalizedSample,"footer-props":{
              'items-per-page-options': [10, 20, 30]
            }},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
            var item = ref.item;
return [_c('span',[(item.type === 'agenda')?_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-format-list-numbered")]):_vm._e(),(item.type === 'region')?_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table")]):_vm._e()],1)]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [(item.type === 'agenda')?_c('span',{staticClass:"d-flex py-6 flex-column"},[_c('span',{staticClass:"font-weight-bold d-flex text-uppercase mb-1"},[_vm._v(" Agenda ")]),_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]):_vm._e(),(item.type === 'region')?_c('span',{staticClass:"font-weight-bold d-flex text-uppercase py-6"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(item.type === 'item')?_c('span',{staticClass:"d-flex text-caption py-4"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-end"},[(item.type==='item' || item.type==='agenda')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"darkGrey","size":"18"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" View ")])]):_vm._e(),(item.type==='item' || item.type==='agenda')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"darkGrey","size":"18"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Download ")])]):_vm._e()],1)]}}])})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"py-14 d-flex justify-center",attrs:{"color":"darkGrey","elevation":"0"}},[_c('v-card',{staticStyle:{"width":"4.25in","height":"5.5in"},attrs:{"color":"white","tile":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }