<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-btn
            class="mr-3 secondary--text pa-0 pr-3"
            text
            @click="$router.push({ name: 'Meetings' })"
          >
            <v-icon size="30">mdi-chevron-left</v-icon>
            Meetings
          </v-btn>
          <h2 class="darkGrey--text d-flex flex-column custom-section-title">
            <span>Finalized <span class="font-weight-black">Meeting Name</span></span>
            <p class="text-body-1">for Board Name</p>
          </h2>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Board Members
            </span>
          </v-tooltip>
        </span>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card
        >
          <v-data-table
            :headers="headers"
            hide-default-header
            hide-default-footer
            :items="finalizedSample"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
          >
            <template v-slot:item.type="{ item }">
              <span>
                <v-icon v-if="item.type === 'agenda'" color="secondary">mdi-format-list-numbered</v-icon>
                <v-icon v-if="item.type === 'region'" color="secondary">mdi-table</v-icon>
              </span>
            </template>
            <template v-slot:item.name="{ item }">
              <span v-if="item.type === 'agenda'" class="d-flex py-6 flex-column">
                <span class="font-weight-bold d-flex text-uppercase mb-1">
                  Agenda
                </span>
                <span>
                  {{ item.name }}
                </span>
              </span>
              <span v-if="item.type === 'region'" class="font-weight-bold d-flex text-uppercase py-6">
                {{ item.name }}
              </span>
              <span v-if="item.type === 'item'" class="d-flex text-caption py-4">
                {{ item.name }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex flex-row justify-end">

                <v-tooltip top v-if="item.type==='item' || item.type==='agenda'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="20">
                        mdi-eye-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    View
                  </span>
                </v-tooltip>

                <v-tooltip top v-if="item.type==='item' || item.type==='agenda'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="20">
                        mdi-download
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Download
                  </span>
                </v-tooltip>

<!--                <v-menu transition="slide-x-transition" v-if="item.type==='item' || item.type==='agenda'">-->
<!--                  <template v-slot:activator="{ on, attrs }">-->
<!--                    <v-btn-->
<!--                      v-bind="attrs"-->
<!--                      v-on="on"-->
<!--                      icon-->
<!--                    >-->
<!--                      <v-icon size="20">mdi-dots-vertical</v-icon>-->
<!--                    </v-btn>-->
<!--                  </template>-->
<!--                  <v-list>-->
<!--                    <v-list-item-group>-->
<!--                      <v-list-item-->
<!--                        v-for="(itemMenu, index) in itemsMenu"-->
<!--                        :key="index"-->
<!--                        @click="openModal(itemMenu.actions, itemMenu.optional)"-->
<!--                        dense-->
<!--                      >-->
<!--                        <v-list-item-icon>-->
<!--                          <v-icon-->
<!--                            :color="itemMenu.type? itemMenu.type:'darkGrey'"-->
<!--                          >-->
<!--                            {{ itemMenu.icon }}-->
<!--                          </v-icon>-->
<!--                        </v-list-item-icon>-->
<!--                        <v-list-item-content>-->
<!--                          <v-list-item-title-->
<!--                            :class="itemMenu.type? itemMenu.type+'&#45;&#45;text':'darkGrey&#45;&#45;text'"-->
<!--                          >-->
<!--                            {{ itemMenu.text }}-->
<!--                          </v-list-item-title>-->
<!--                        </v-list-item-content>-->
<!--                      </v-list-item>-->
<!--                    </v-list-item-group>-->
<!--                  </v-list>-->
<!--                </v-menu>-->

              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          color="darkGrey"
          elevation="0"
          class="py-14 d-flex justify-center"
        >
          <v-card
            color="white"
            tile
            style="width: 4.25in; height: 5.5in"
          >
            &nbsp;
          </v-card>
        </v-card>
      </v-col>
    </v-row>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'

export default Vue.extend({
  name: 'MeetingFinalized',
  components: {
    Portal
  },
  data: () => ({
    modeType: '',
    modals: {
      editOriginal: false
    },
    breadcrumbsItems: [
      {
        text: 'Meetings',
        disabled: false,
        href: ''
      },
      {
        text: 'Finalized Meeting Name',
        disabled: true,
        href: '/boards'
      }
    ],
    itemsMenu: [
      { icon: 'mdi-download', text: 'Download', actions: 'download' },
      { icon: 'mdi-email', text: 'Email', actions: 'email' },
      { icon: 'mdi-text', text: 'Text', actions: 'text' }
    ],
    headers: [
      { text: '', value: 'type' },
      { text: 'Label', value: 'name' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ],
    finalizedSample: [

      {
        name: 'Saturday, October 28, 2018 7:00 PM - Regular Meeting',
        type: 'agenda'
      },
      {
        name: 'Minutes',
        type: 'region'
      },
      {
        name: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr…',
        type: 'item'
      },
      {
        name: 'Diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum',
        type: 'item'
      },
      {
        name: 'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed...',
        type: 'item'
      },
      {
        name: 'New Business',
        type: 'region'
      },
      {
        name: 'Old Business',
        type: 'region'
      },
      {
        name: 'Consent agenda',
        type: 'region'
      },
      {
        name: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr…',
        type: 'item'
      },
      {
        name: 'Diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum',
        type: 'item'
      },
      {
        name: 'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed...',
        type: 'item'
      }
    ]
  }),
  methods: {
    openModal (item, optional) {
      if (optional) this.modeType = optional
      this.modals[item] = true
    }
  }
})
</script>
